import { memo, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'

import { colors } from 'libs/theme'
import { heights } from 'libs/items'
import { doWithTagManager } from 'libs/local'

import { Grid, Text } from 'components/elements'
import { Button } from 'components/elements/Buttons'
import Icons from 'components/elements/Icons'

const Navigation = memo(({ current = 'home' }) => {
  const router = useRouter()
  const { breakpoints, safeAreaInsets, user } = useSelector(state => state.default)
  const [selected, setselected] = useState(current)
  const [isInit, setisInit] = useState(false)
  const [isNoNavigator, setisNoNavigator] = useState(false)

  const isNoNavigatorPages = ['/account']
  const items = [
    { key: 'home', name: '홈', page: '/', same: '/', gtm: 'gtm_click_nav_home' },
    { key: 'routine', name: '루틴', page: '/routine', indexOf: '/routine', gtm: 'gtm_click_nav_routine' },
    { key: 'counsel', name: '시술&수술', page: '/doctor/treatment', indexOf: '/doctor', gtm: 'gtm_click_nav_treatment' },
    { key: 'journal', name: '저널', page: '/community/journal', indexOf: '/journal' },
    { key: 'hospital', name: '병원 진료', page: '/hospital', indexOf: '/hospital' }
  ]

  useEffect(() => {
    const f = isNoNavigatorPages.find(p => router?.pathname.indexOf(p) > -1)
    if (f) setisNoNavigator(true)
    else {
      setisNoNavigator(false)
      const finded = items.find(item => (item.same && item.same === router?.pathname) || (item.indexOf && router?.pathname.indexOf(item.indexOf) > -1))
      if (finded) setselected(finded.key)
      else setselected()
    }
  }, [router?.asPath])

  useEffect(() => {
    setisInit(true)
  }, [])

  if (!isInit || isNoNavigator) return null

  const movePage = (page) => {
    // 네비게이션을 통한 루틴 유입
    if (page.startsWith('/routine')) {
      // 로그인 했을 경우 - 루틴 서비스 경험자 => 오늘의 루틴 페이지 랜딩
      if (!!user && user.agree_routine_alarm !== null) {
        router.push(page)
      } else {
        // 로그인 했을 경우 - 루틴 서비스 미경험자 => 온보딩 페이지 랜딩
        // 로그인 안했을 경우 - 이미 회원인경우 => 온보딩 페이지 랜딩
        // 로그인 안했을 경우 - 회원이 아닐 경우 => 온보딩 페이지 랜딩
        router.push('/routine/onboarding')
      }
    } else {
      router.push(page)
    }
  }

  return (
    <Grid columns={`repeat(${items.length}, 1fr)`} position='fixed' color={colors.white} width='100%' maxWidth={`${heights.maxWidth}px`}
          padding={`4px 0 ${8 + (breakpoints?.isIos && !!safeAreaInsets ? 8 : 0)}px 0`}
          style={{ bottom: '0px', zIndex: 111, boxShadow: '0 -8px 10px 0 rgba(5, 67, 94, 0.05)' }}>
      {
        items.map(item => (
          <Button key={item.key} variant='text' onClick={() => doWithTagManager(item.gtm, () => movePage(item.page))}>
            <Grid justify='center' align='center' padding='4px' gap='4px' height='40px'>
              <Icons icon={item.key} width='24px' height='24px' color={selected === item.key ? colors.primary.light : colors.disabled} />
              <Text variant='label1' bold color={selected === item.key ? colors.primary.light : colors.disabled}>{item.name}</Text>
            </Grid>
          </Button>
        ))
      }
    </Grid>
  )
}, () => {
  return true
})

export default Navigation
