import React, { memo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'

import Terms from 'components/commons/Terms'
import { Grid, Text } from 'components/elements'
import { colors, spaces } from 'libs/theme'
import { Button } from 'components/elements/Buttons'
import Icons from 'components/elements/Icons'

const Footer = memo(() => {
  const { breakpoints } = useSelector(state => state.default)
  const router = useRouter()
  const [isTerms, setisTerms] = useState(false)
  const [isOpen, setisOpen] = useState(false)

  return (
    <>
      <footer style={{ paddingBottom: 72 }}>
        <div className='ftr-inner'>
          <div className='about-support'>
            <div className='about'>
              <h3>About</h3>
              <ul>
                <li><a onClick={() => setisTerms(true)}>이용약관</a></li>
                <li><a onClick={() => window.open('https://app.catchsecu.com/document/P/48e0e70fc209e78')}>개인정보 처리방침</a></li>
                <li><a onClick={() => window.open('https://app.catchsecu.com/document/P/31edf0e1425397e')}>개인정보 처리방침(파트너)</a></li>
              </ul>
            </div>
          </div>
          <Grid padding={`${spaces.component.s4.px} ${spaces.default.px}`}>
            <Grid gap={spaces.component.s1.px}>
              <Grid display='flex' justify='space-between' align='center'>
                <Text variant='caption2' color={colors.grey.g700} family='Pretendard Variable'>(주) 트러스트랩스 | Trust Labs Inc.</Text>
                <Button padding='0' variant='text' onClick={() => setisOpen(is => !is)}>
                  <Icons icon='arrow-curved' width='18' height='18' color={colors.grey.g700} rotate={isOpen ? '0' : '180'} />
                </Button>
              </Grid>
              {
                isOpen && (
                  <>
                    <Text variant='caption3' color={colors.grey.g600} family='Pretendard Variable'>서울시 서초구 서초대로 398 BNK 디지털타워 5층</Text>
                    <Text variant='caption3' color={colors.grey.g600} family='Pretendard Variable'>고객센터 : 1668-3861 (9:30 - 18:30)</Text>
                    <Text variant='caption3' color={colors.grey.g600} family='Pretendard Variable'>이메일 : info@sirs.kr</Text>
                  </>
                )
              }
            </Grid>
            {
              isOpen && (
                <>
                  <Grid marginTop={spaces.contents.s1.px} gap={spaces.component.s1.px}>
                    <Text variant='caption3' color={colors.grey.g600} family='Pretendard Variable'>대표이사 : 김태우</Text>
                    <Text variant='caption3' color={colors.grey.g600} family='Pretendard Variable'>사업자등록번호 : 261-88-02197</Text>
                    <Text variant='caption3' color={colors.grey.g600} family='Pretendard Variable'>통신판매업신고번호 : 제2022-서울서초-1324호</Text>
                  </Grid>
                  <Grid marginTop={spaces.contents.s1.px}>
                    <Button padding='0' radius='0' variant='text' width='fit-content'
                            onClick={() => window.open('https://sirs.channel.io/')}>
                      <Grid gap={spaces.component.s3.px} align='center' display='flex'>
                        <Icons icon='building' width='18' height='18' color={colors.grey.g700} />
                        <Text color={colors.grey.g700} variant='caption2' family='Pretendard Variable'>병원&약국 제휴 및 입점 문의</Text>
                      </Grid>
                    </Button>
                  </Grid>
                  <Grid marginTop={spaces.contents.s1.px}>
                    <Text color={colors.grey.g500} variant='label2' family='Pretendard Variable'>©Trust Labs Inc. All rights reserved.</Text>
                  </Grid>
                </>
              )
            }
          </Grid>
        </div>
      </footer>
      <Terms isOpen={isTerms} isFull={!breakpoints.md} setIsOpen={setisTerms} />
    </>
  )
}, () => {
  return true
})

export default Footer
